<template>
  <div class="wrapper">
    <v-card>
      <v-card-title>
        Open Tasks
        <v-spacer />
        <!-- <v-switch
          style="display: inline-flex"
          v-model="allCustomers"
          label="All Customers"
          @change="fetchTasks"
        ></v-switch>
         -->
        <v-btn
          @keydown.enter="createNewTask()"
          color="primary"
          @click="isDialogCreateTask = true"
        >
          Create Task
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-feature-search-outline"
          label="Search"
          single-line
          hide-details
          autofocus
        />
        <v-data-table
          :headers="headers"
          :items="fetchedOpenTasks"
          :loading="isLoading"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          :options="options"
          :mobile-breakpoint="null"
          item-key="external_id"
          class="elevation-1"
          :search="search"
          v-model="selectedTasks"
        >
          <!-- <template v-slot:[`item.name`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            {{ item.name }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="openLink(item, true)">
                  mdi-open-in-new
                </v-icon>
              </template>
              <span style="font-size: 12px">Open Link {{ item.origin }}</span>
            </v-tooltip>
          </template> -->
          <template v-slot:[`item.isClosed`]="{ item }">
            <v-icon v-if="loadingTasks[fetchedTasks.indexOf(item)]"
              >mdi-refresh mdi-spin</v-icon
            >
            <v-icon
              v-else
              @click="setStatusDone(item, !Boolean(item.isClosed))"
            >
              {{
                item.isClosed
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <div v-if="isEditComments[fetchedTasks.indexOf(item)]">
              <v-text-field
                v-model="tmpEditComments[fetchedTasks.indexOf(item)]"
                :hide-details="true"
                dense
                single-line
                v-if="isEditComments[fetchedTasks.indexOf(item)]"
                @keydown.enter="setComment(item)"
              ></v-text-field>
              <v-icon color="green" class="mr-3" @click="setComment(item)">
                mdi-check
              </v-icon>
              <v-icon
                color="red"
                class="mr-3"
                @click="cancelCommentForEdit(item)"
              >
                mdi-window-close
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                color="green"
                class="mr-3"
                @click="openCommentForEdit(item)"
              >
                mdi-pencil
              </v-icon>
              {{ item.comment }}
            </div>
          </template>

          <!--
          <template v-slot:[`item.status`]="{ item }">
               <v-menu offset-y transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="getColorForStatus(item.status)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :loading="loadingTasks[fetchedTasks.indexOf(item)]"
                >
                  <v-icon>{{ getIconForStatus(item.status) }}</v-icon>
                  {{ item.status }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(statusAlternative, index) in taskStatusAlternatives"
                  :key="index"
                >
                  <v-btn
                    :color="getColorForStatus(statusAlternative.name)"
                    dark
                    @click="setStatus(item, statusAlternative.name)"
                  >
                    <v-icon>{{
                      getIconForStatus(statusAlternative.name)
                    }}</v-icon>
                    {{ statusAlternative.name }}
                  </v-btn>
                  
                </v-list-item>
              </v-list>
            </v-menu> -->
          <!-- </template> -->

          <template v-slot:[`item.origin`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            {{ item.name }} -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="clickable" v-on="on" @click="openLink(item, true)">
                  <img
                    v-if="item.origin === 'slack'"
                    width="20px"
                    src="@/assets/img/icons/slack_logo.svg"
                    alt=""
                  />
                  <img
                    v-if="item.origin === 'website'"
                    width="20px"
                    src="@/assets/img/logo_short.svg"
                  />

                  <a v-if="item.link" :href="item.origin" target="_blank">
                    {{ capitalizeLetter(item.createdByName) }}
                    {{ dateFormatter(item.createAt) }}
                  </a>
                  <span v-else>
                    {{ capitalizeLetter(item.createdByName) }}
                    {{ dateFormatter(item.createAt) }}
                  </span>
                </span>
              </template>
              <span style="font-size: 12px">Open Link {{ item.origin }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.text`]="{ item }">
            <span v-for="(file, index) in item.files" :key="`files_${index}`">
              <v-icon v-if="file.mode === 'email'" class="mr-2">
                mdi-email
              </v-icon>
              <span
                v-for="(attachment, index) in file.attachments"
                :key="`file_attachments_${index}`"
              >
                <a
                  v-if="
                    attachment.mimetype && attachment.mimetype.includes('image')
                  "
                  :href="attachment.url"
                  target="_blank"
                >
                  [IMAGE]
                </a>

                <!-- Cross-Origin Read Blocking                
                <v-menu
                  v-if="
                    attachment.mimetype && attachment.mimetype.includes('image')
                  "
                  open-on-hover
                  close-delay="150"
                  open-delay="150"
                >                
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <img :src="attachment.url" width="30px" />
                    </span>
                  </template>
                  <img class="pa-2" :src="attachment.url" height="500" /> 
                </v-menu>
                  -->
                <span v-else> [ATTACHMENT] </span>
              </span>
            </span>
            {{ item.text }}
            <span
              v-for="(attachment, index) in item.attachments"
              :key="`attachments_${index}`"
            >
              <v-menu
                v-if="attachment.image_url"
                open-on-hover
                close-delay="150"
                open-delay="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <img :src="attachment.image_url" width="30px" />/
                  </span>
                </template>
                <img class="pa-2" :src="attachment.image_url" height="500" />
              </v-menu>
            </span>
          </template>

          <!-- <template v-slot:[`item.preview_url`]="{ item }">
            <v-menu open-on-hover close-delay="150" open-delay="150">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <img :src="item.preview_url" height="60" />
                </span>
              </template>
              <img class="pa-2" :src="item.preview_url" height="500" />
            </v-menu>
          </template> -->
        </v-data-table>
      </v-card-text>
      <!-- <v-card-actions class="justify-right">
        <v-spacer></v-spacer>

        <v-btn
          rounded
          :loading="isLoading"
          elevation="2"
          raised
          text
          color="primary"
          dark
          @click="fetchTasks"
        >
          Refresh
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <v-card class="mt-4">
      <v-card-title>
        Closed Tasks
        <v-spacer />
        <!-- <v-switch
          style="display: inline-flex"
          v-model="allCustomers"
          label="All Customers"
          @change="fetchTasks"
        ></v-switch>
         -->
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-feature-search-outline"
          label="Search"
          single-line
          hide-details
          autofocus
        />
        <v-data-table
          :headers="headers"
          :items="fetchedClosedTasks"
          :loading="isLoading"
          :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
          :options="options"
          :mobile-breakpoint="null"
          item-key="external_id"
          class="elevation-1"
          :search="search"
          v-model="selectedTasks"
        >
          <!-- <template v-slot:[`item.name`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            {{ item.name }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" @click="openLink(item, true)">
                  mdi-open-in-new
                </v-icon>
              </template>
              <span style="font-size: 12px">Open Link {{ item.origin }}</span>
            </v-tooltip>
          </template> -->
          <template v-slot:[`item.isClosed`]="{ item }">
            <v-icon v-if="loadingTasks[fetchedTasks.indexOf(item)]"
              >mdi-refresh mdi-spin</v-icon
            >
            <v-icon
              v-else
              @click="setStatusDone(item, !Boolean(item.isClosed))"
            >
              {{
                item.isClosed
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <div v-if="isEditComments[fetchedTasks.indexOf(item)]">
              <v-text-field
                v-model="tmpEditComments[fetchedTasks.indexOf(item)]"
                :hide-details="true"
                dense
                single-line
                v-if="isEditComments[fetchedTasks.indexOf(item)]"
                @keydown.enter="setComment(item)"
              ></v-text-field>
              <v-icon color="green" class="mr-3" @click="setComment(item)">
                mdi-check
              </v-icon>
              <v-icon
                color="red"
                class="mr-3"
                @click="cancelCommentForEdit(item)"
              >
                mdi-window-close
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                color="green"
                class="mr-3"
                @click="openCommentForEdit(item)"
              >
                mdi-pencil
              </v-icon>
              {{ item.comment }}
            </div>
          </template>

          <!--
          <template v-slot:[`item.status`]="{ item }">
               <v-menu offset-y transition="fab-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="getColorForStatus(item.status)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :loading="loadingTasks[fetchedTasks.indexOf(item)]"
                >
                  <v-icon>{{ getIconForStatus(item.status) }}</v-icon>
                  {{ item.status }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(statusAlternative, index) in taskStatusAlternatives"
                  :key="index"
                >
                  <v-btn
                    :color="getColorForStatus(statusAlternative.name)"
                    dark
                    @click="setStatus(item, statusAlternative.name)"
                  >
                    <v-icon>{{
                      getIconForStatus(statusAlternative.name)
                    }}</v-icon>
                    {{ statusAlternative.name }}
                  </v-btn>
                  
                </v-list-item>
              </v-list>
            </v-menu> -->
          <!-- </template> -->

          <template v-slot:[`item.origin`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            {{ item.name }} -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="clickable" v-on="on" @click="openLink(item, true)">
                  <img
                    v-if="item.origin === 'slack'"
                    width="20px"
                    src="@/assets/img/icons/slack_logo.svg"
                    alt=""
                  />
                  <img
                    v-if="item.origin === 'website'"
                    width="20px"
                    src="@/assets/img/logo_short.svg"
                  />

                  <a v-if="item.link" :href="item.origin" target="_blank">
                    {{ capitalizeLetter(item.createdByName) }}
                    {{ dateFormatter(item.createAt) }}
                  </a>
                  <span v-else>
                    {{ capitalizeLetter(item.createdByName) }}
                    {{ dateFormatter(item.createAt) }}
                  </span>
                </span>
              </template>
              <span style="font-size: 12px">Open Link {{ item.origin }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.text`]="{ item }">
            <span v-for="(file, index) in item.files" :key="`files_${index}`">
              <v-icon v-if="file.mode === 'email'" class="mr-2">
                mdi-email
              </v-icon>
              <span
                v-for="(attachment, index) in file.attachments"
                :key="`file_attachments_${index}`"
              >
                <a
                  v-if="
                    attachment.mimetype && attachment.mimetype.includes('image')
                  "
                  :href="attachment.url"
                  target="_blank"
                >
                  [IMAGE]
                </a>

                <!-- Cross-Origin Read Blocking                
                <v-menu
                  v-if="
                    attachment.mimetype && attachment.mimetype.includes('image')
                  "
                  open-on-hover
                  close-delay="150"
                  open-delay="150"
                >                
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <img :src="attachment.url" width="30px" />
                    </span>
                  </template>
                  <img class="pa-2" :src="attachment.url" height="500" /> 
                </v-menu>
                  -->
                <span v-else> [ATTACHMENT] </span>
              </span>
            </span>
            {{ item.text }}
            <span
              v-for="(attachment, index) in item.attachments"
              :key="`attachments_${index}`"
            >
              <v-menu
                v-if="attachment.image_url"
                open-on-hover
                close-delay="150"
                open-delay="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <img :src="attachment.image_url" width="30px" />/
                  </span>
                </template>
                <img class="pa-2" :src="attachment.image_url" height="500" />
              </v-menu>
            </span>
          </template>

          <!-- <template v-slot:[`item.preview_url`]="{ item }">
            <v-menu open-on-hover close-delay="150" open-delay="150">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <img :src="item.preview_url" height="60" />
                </span>
              </template>
              <img class="pa-2" :src="item.preview_url" height="500" />
            </v-menu>
          </template> -->
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-right">
        <v-spacer></v-spacer>

        <v-btn
          rounded
          :loading="isLoading"
          elevation="2"
          raised
          text
          color="primary"
          dark
          @click="fetchTasks"
        >
          Refresh
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isDialogCreateTask" persistent max-width="1000px">
      <v-card>
        <v-card-title class="text-h5">Add Task</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                v-model="newTaskObject.text"
                label="Text"
                :hide-details="true"
                dense
                single-line
              ></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="isDialogCreateTask = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            :loading="isLoadingCreateNewTask"
            color="blue darken-1"
            text
            @click="createNewTask"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import H from "@/utils/helper";
import { TASK_STATUS } from "@/constants/globals";

export default {
  name: "TasksList",

  components: {},
  data: () => ({
    editedIndex: null,
    editedItem: {
      tags: [],
      styles: [],
      domains: [],
      enabled: true,
      topic_sub_ids: [],
      template_type: null,
      customer_ids: [],
    },
    taskStatusAlternatives: TASK_STATUS,
    currentEditOptionalCustomers: [],
    currentEditOptionalTemplateTypes: [],
    topicsBeauty: [],
    topicsRealestate: [],
    forceAllTopics: false,
    forceAllCustomers: true,
    isLoading: true,
    isError: false,
    search: "",
    allCustomers: false,
    fetchedTasks: [],
    selectedTasks: [],
    loadingTasks: [],
    isDialogCreateTask: false,
    isLoadingCreateNewTask: false,
    isEditComments: [],
    tmpEditComments: [],
    newTaskObject: {},
    headers: [
      // {
      //   text: "id",
      //   value: "id",
      // },
      // {
      //   text: "customerId",
      //   value: "customerId",
      // },
      // {
      //   text: "Type",
      //   value: "taskType",
      // },
      {
        text: "Done",
        value: "isClosed",
        width: "5%",
      },
      // {
      //   text: "Status",
      //   value: "status",
      //   width: "10%",
      // },
      {
        text: "Created",
        value: "origin",
        width: "15%",
      },
      // {
      //   text: "createdByName",
      //   value: "createdByName",
      // },
      // {
      //   text: "Priority",
      //   value: "priority",
      // },
      {
        text: "Text",
        value: "text",
        width: "40%",
      },
      {
        text: "Comment",
        value: "comment",
        width: "40%",
      },
      // {
      //   text: "createAt",
      //   value: "createAt",
      // },
      // {
      //   text: "updatedAt",
      //   value: "updatedAt",
      // },
      // {
      //   text: "link",
      //   value: "link",
      // },
    ],
    rowsPerPageItems: [100, 200],
    options: {
      sortBy: ["isClosed", "createdAt"],
      // sortDesc: false,
      multiSort: false,
      // itemsPerPage: 100,
    },
  }),
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),

    fetchedOpenTasks() {
      return this.fetchedTasks.filter((t) => !t.isClosed);
    },
    fetchedClosedTasks() {
      return this.fetchedTasks.filter((t) => t.isClosed);
    },
  },

  async created() {
    await this.fetchTasks();
  },
  methods: {
    ...mapActions("task", ["getTask", "listTasks", "updateTask", "createTask"]),

    dateFormatter(date) {
      return this.$dayjs.unix(date).format("DD.MM.YY");
    },

    capitalizeLetter(string) {
      return H.capitalizeFirstLetter(string);
    },
    getIconForOrigin(item) {
      if (item.origin === "slack") return "mdi-slack";
      return "mdi-open-in-new";
    },

    getIconForStatus(statusName) {
      const statusObj = this.taskStatusAlternatives.filter(
        (i) => i.name === statusName
      )[0];
      return statusObj.icon;
    },
    getColorForStatus(statusName) {
      const statusObj = this.taskStatusAlternatives.filter(
        (i) => i.name === statusName
      )[0];
      return statusObj.color;
    },

    async createNewTask() {
      this.isLoadingCreateNewTask = true;
      const params = { ...this.newTaskObject };
      params["customerId"] = this.getCurrentCustomer.id;
      params["createdById"] = this.getCurrentUser.id;
      params["origin"] = "website";
      params["status"] = "Todo";
      params["priority"] = "high";
      params["taskType"] = "todo";

      await this.createTask(params);
      this.isLoadingCreateNewTask = false;
      this.isDialogCreateTask = false;
      await this.fetchTasks();
    },

    // async setStatus(item, newStatus) {
    //   const index = this.fetchedTasks.findIndex((t) => t.id == item.id);
    //   this.loadingTasks[index] = true;
    //   console.log("setting new status for", item, newStatus);
    //   const params = { ...item, ...{ status: newStatus } };
    //   const taskId = item.id;
    //   await this.updateTask({ taskId, params });

    //   this.loadingTasks[index] = false;
    //   let tasks = [...this.fetchedTasks];
    //   tasks[index] = params;
    //   this.fetchedTasks = tasks;
    //   this.sortTasks();
    // },

    setLoadingTasks(index, value) {
      const tmp = [...this.loadingTasks];
      tmp[index] = value;
      this.loadingTasks = tmp;
    },

    async setStatusDone(item, newValue) {
      let tasks = [...this.fetchedTasks];
      const index = tasks.findIndex((t) => t.id == item.id);
      this.setLoadingTasks(index, true);
      const status = newValue ? "Done" : "Todo";
      const params = { ...item, ...{ isClosed: newValue, status: status } };
      const taskId = item.id;
      await this.updateTask({ taskId, params });

      this.setLoadingTasks(index, false);
      tasks[index] = params;
      this.fetchedTasks = tasks;
      this.sortTasks();
    },
    async setComment(item) {
      const index = this.fetchedTasks.findIndex((t) => t.id == item.id);
      const newValue = this.tmpEditComments[index];
      this.setLoadingTasks(index, true);
      const params = { ...item, ...{ comment: newValue } };
      const taskId = item.id;
      await this.updateTask({ taskId, params });

      this.setLoadingTasks(index, false);
      let tasks = [...this.fetchedTasks];
      tasks[index] = params;
      this.fetchedTasks = tasks;
      this.closeCommentForEdit(index);
    },

    openCommentForEdit(item) {
      const index = this.fetchedTasks.indexOf(item);
      const tmp = [...this.isEditComments];
      tmp[index] = true;
      this.tmpEditComments[index] = this.fetchedTasks[index].comment;
      this.isEditComments = tmp;
    },
    closeCommentForEdit(index) {
      const tmp = [...this.isEditComments];
      tmp[index] = false;
      this.isEditComments = tmp;
    },
    cancelCommentForEdit(item) {
      const index = this.fetchedTasks.indexOf(item);
      this.closeCommentForEdit(index);
      this.tmpEditComments[index] = item.comment;
    },

    // getStyleForStatusInMenu(statusAlternative) {

    //   const color = this.getColorForStatus({ status: statusAlternative.name })
    //   return {
    //     "background-color": color
    //   }
    // },

    tagsChanged(val) {
      if (!val && !val.length) return;
      this.editedItem.tags = H.tagsCompose(val);
    },

    openLink(item) {
      window.open(item.link, "_blank");
    },

    async saveItemConfirm() {
      try {
        let params = this.editedItem;
        await this.modifyTemplate(params);
      } finally {
        this.isDialogCreateTask = false;
        await this.fetchTasks();
      }
    },

    async sortTasks() {
      // this.fetchedTasks.sort((a, b) => {
      // const a_status_index = this.taskStatusAlternatives.findIndex(
      //   (i) => i.name === a.status
      // );
      // const b_status_index = this.taskStatusAlternatives.findIndex(
      //   (i) => i.name === b.status
      // );
      // return a_status_index < b_status_index ? -1 : 1;
      //   return a.isClosed < b.isClosed ? -1 : 1;
      // });
    },

    async fetchTasks() {
      try {
        this.isLoading = true;
        let params = {};

        if (!this.allCustomers) {
          params["customerId"] = this.getCurrentCustomer.id;
        }

        this.fetchedTasks = await this.listTasks(params);
        this.sortTasks();
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  padding: 20px;
}
.content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  div {
    align-self: center;
    h2 {
      font-size: 40px;
      text-align: center;
    }
    img {
      height: 200px;
      width: 200px;
    }
  }
}
.clickable {
  cursor: pointer;
}
</style>
